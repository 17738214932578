import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'

export const initToggleButton = element => {
  function toggle (isOn, active = false) {
    element.classList[isOn ? 'add' : 'remove']('toggle-button--active')
    if (active) {
      fireCustomEvent('toggle-button__toggle', { identifier, state: isOn })
    }
  }
  const identifier = element.getAttribute('data-identifier')
  element.addEventListener('click', () => toggle(!element.classList.contains('toggle-button--active'), true))
  window.addEventListener('toggle-button__update', event => {
    if (event.detail.identifier === identifier) {
      toggle(event.detail.state)
    }
  })
}

window.addEventListener('DOMContentLoaded', () => {
  [...document.querySelectorAll('.toggle-button')].forEach(initToggleButton)
})
