/*
---------------------------------------------
DISABLED: scroll functionality
---------------------------------------------
 */
import Component from '../../../assets/scripts/modules/component'

class HeroHeaderComponent extends Component {
  init () {
    // this.scrollMove()
  }

  // Disabled -- leaving this here incase they want a similar behavior in the future.
  scrollMove () {
    if (window.innerWidth > 800) {
      const headerBlock = document.getElementById('hero-header__wrapper')
      window.addEventListener('scroll', function () {
        const st = window.pageYOffset || document.documentElement.scrollTop
        const scrollPercentage = Math.round((st * (100 / window.innerHeight)))
        if (scrollPercentage < 100) {
          headerBlock.style.transform = `translateX(-${scrollPercentage / 2}%)`
        }
      }, false)
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.hero-header').forEach(element => {
  element.instance = element.instance || new HeroHeaderComponent(element)
}))
