const BASE_URL = 'https://b.micr.io/'
const CACHE = {}

export default class DataService {
  async download (url, cacheMinutes = 1) {
    if (url in CACHE && CACHE[url].key + 60000 * cacheMinutes >= new Date()) {
      return JSON.parse(CACHE[url].data)
    }

    let data

    try {
      const response = await fetch(url)
      data = await response.json()
    } catch (e) {
      console.log(`Could not download ${url}: ${e}`)
      return {}
    }

    CACHE[url] = { key: new Date(), data }
    return data
  }

  async getInfo (micrioId, cache = 1) {
    return this.download(`${BASE_URL}${micrioId}/info.json`, cache)
  }

  async getData (micrioId, lang = 'nl', cache = 1) {
    return this.download(`${BASE_URL}${micrioId}/data.${lang}.json`, cache)
  }

  async getMarker (micrioShortId, markerSlug, lang = 'nl') {
    const data = await this.getData(micrioShortId, lang)
    return data?.markers?.find(m => m.slug === markerSlug)
  }
}
