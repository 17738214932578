import DataService from '../data/DataService'

const STATE = {
  name: 'tour',
  urls: [
    {
      match: '^tour/(?<slug>[\\w-]+)$',
      handle: async (micrioId, lang = 'nl', element, helpers, params) => {
        const ds = new DataService()
        const data = await ds.getData(micrioId, lang)
        const tour = data.markerTours.find(tour => tour.slug === params.slug)
        if (!tour) {
          return
        }
        if (!tour.steps) {
          return
        }
        const marker = data.markers.find(marker => marker.id === tour.steps[0])
        if (!marker) {
          return
        }
        return helpers.navigate(`tour/${params.slug}/${marker.slug}`)
      }
    }
  ],
  deactivate: async () => {}
}

export default STATE
