
import Component from '../../../assets/scripts/modules/component'
import SwiperWrapper from '../../../assets/scripts/modules/swiper-wrapper'

const SWIPER_OPTIONS = {
  // spaceBetween: 16,
  autoplay: {
    delay: 5000
  },
  loop: true
  // loopAdditionalSlides: 4
  // breakpointsInverse: true,
  // breakpoints: {
  //   530: { slidesPerView: 1 },
  //   800: { slidesPerView: 1 },
  //   1180: { slidesPerView: 1 }
  // }
}

let timeout

function zeroFill (number, width) {
  width -= number.toString().length
  if (width > 0) {
    return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number
  }
  return number + '' // always return a string
}

export default class ArticlePhotoCarouselComponent extends Component {
  init () {
    this.observer = null
    this.photosContainer = this.element.querySelector('.article-photo-carousel__photos .swiper-container')
    this.captionsContainer = this.element.querySelector('.article-photo-carousel__captions .swiper-container')
    this.captionsSwiperWrapper = new SwiperWrapper(this.captionsContainer, {
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      navigation: false,
      pagination: false,
      watchSlidesVisibility: true,
      watchSlidesProgress: true
    }, [], {}, () => {
      let swiperOptions = {
        ...SWIPER_OPTIONS,
        controller: {
          control: this.captionsSwiperWrapper.swiper
        },
        pagination: {
          el: this.element.querySelector('.swiper-pagination'),
          type: 'custom',
          renderCustom: (s, c, t) => `${zeroFill(c, 2)}/${zeroFill(t, 2)}`
        }
      }

      if ([...this.photosContainer.querySelectorAll('.swiper-slide')].length < 2) {
        swiperOptions = { ...swiperOptions, ...{ noSwipe: true, navigation: true, pagination: true, loop: false } }
        this.element.querySelectorAll('.swiper-button-prev, .swiper-button-next').forEach(button => button.parentNode.removeChild(button))
      }

      this.swiperWrapper = new SwiperWrapper(this.photosContainer, swiperOptions, [], {
        slideChange: () => this.onSwiperSlideChange()
      })

      window.clearTimeout(timeout)
      timeout = window.setTimeout(() => {
        this.onSwiperSlideChange()

        // Warkaround for swiper bug; see https://github.com/nolimits4web/Swiper/issues/2099
        if (SWIPER_OPTIONS.loop) {
          [...this.element.querySelectorAll('.swiper-button-disabled')].forEach(button => {
            button.classList.remove('swiper-button-disabled')
          })
        }
      }, 500)
    })
  }

  onSwiperSlideChange () {
    if (!this.swiperWrapper || !this.swiperWrapper.swiper || !this.swiperWrapper.swiper.slides) {
      return
    }

    const current = this.swiperWrapper.swiper.slides[this.swiperWrapper.swiper.activeIndex]
    const all = Object.values(this.swiperWrapper.swiper.slides).filter(slide => !!slide.tagName)
    const currentWithDuplicates = all.filter(slide => slide.getAttribute('data-swiper-slide-index') === current.getAttribute('data-swiper-slide-index'))

    currentWithDuplicates.forEach(slide => {
      slide.classList.add('swiper-slide--activated')
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.article-photo-carousel').forEach(element => {
  element.instance = element.instance || new ArticlePhotoCarouselComponent(element)
}))
