
import Component from '../../../assets/scripts/modules/component'

class AudioPlayer {
  /** Copied from our friends at Q42 */

  hook (_audio, _player, _trackerline, _tracker, _time, _play, _pause, _length) {
    _audio.oncanplay = () => {
      _length.textContent = this.formatTime(_audio.duration)

      _tracker.onmousedown = ev => {
        // Only respond to LMB
        if (ev.which !== 1) return
        const percent = ev.offsetX / _tracker.offsetWidth
        _audio.currentTime = percent * _audio.duration

        // If not playing, start
        _audio.play()
      }
    }

    _audio.ontimeupdate = () => {
      _time.textContent = this.formatTime(_audio.currentTime)
      _trackerline.style.width = (_audio.currentTime / _audio.duration) * 100 + '%'
    }

    _audio.onended = () => {
      _audio.currentTime = 0
      _trackerline.style.width = '0%'
      _time.textContent = this.formatTime(_audio.duration)
    }

    _play.onclick = () => {
      console.log('hey')

      _audio.play()
    }
    _pause.onclick = () => {
      console.log('ho')
      _audio.pause()
    }

    _audio.onplay = () => {
      _player.classList.remove('paused')
      _player.classList.remove('ended')
    }
    _audio.onpause = () => {
      _player.classList.add('paused')
    }

    _audio.load()
  }

  formatTime (time) {
    if (isNaN(time)) return ':-'
    let sec = Math.round(time % 60)
    if (sec < 10) sec = `0${sec}`
    return `${Math.floor(time / 60)}:${sec}`
  }
}

class AudioFragmentComponent extends Component {
  init () {
    const _audio = this.element.querySelector('audio')
    const _player = this.element.querySelector('.audio-fragment__player')
    const _trackerline = this.element.querySelector('.audio-fragment__line')
    const _tracker = this.element.querySelector('.audio-fragment__progress-tracker')
    const _seeker = this.element.querySelector('.audio-fragment__seeker')
    const _time = _tracker.children[0]
    const _play = this.element.querySelector('.audio-fragment__play')
    const _pause = this.element.querySelector('.audio-fragment__pause')
    const _length = _tracker.children[2]
    this.audioPlayer = new AudioPlayer()
    this.audioPlayer.hook(_audio, _player, _trackerline, _seeker, _time, _play, _pause, _length)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.audio-fragment').forEach(element => {
  element.instance = element.instance || new AudioFragmentComponent(element)
}))
