import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'
import DataService from './data/DataService'
import DetailState from './states/detail'
import ExploreState from './states/explore'
import IntroState from './states/intro'
import TourState from './states/tour'

export const EVENT_ACTIVATE = 'activate'
export const EVENT_DEACTIVATE = 'deactivate'

const STATES = [
  DetailState,
  ExploreState,
  IntroState,
  TourState
]

let tempMuted = false
const place = []

// Because screw iOS [sic]
const noAudio = /ipad|iphone|ipod/i.test(navigator.userAgent)
if (noAudio) document.documentElement.classList.add('muted')

function CMD (micrioComponent, container, el, id, width, height, minimap = false, cover = false, is360 = false, isPng = false, tilesId = null) {
  if (container.micrio) return
  container.micrio = new window.Micrio({
    path: 'https://b.micr.io/',
    id: id,
    tilesId: tilesId,
    legacyTiles: true,
    container: container,
    canvas: el,
    width: width,
    height: height,
    minimap: minimap,
    zoomLimit: 4,
    doTourJumps: true,
    minimapWidth: 300,
    minimapHeight: 150,
    muted: noAudio,
    is360: is360,
    isPng: isPng,
    limitToCoverScale: is360,
    closeTop: false,
    noLogo: true,
    loaderbar: false,
    controlType: 'none',
    noControls: true,
    noCSS: true,
    shadow: false,
    initType: cover ? 'cover' : null,
    htmlMarkers: true,
    watchAreaAttr: true,
    clearColor: minimap ? [18, 193, 0] : [238, 236, 233],
    forceAudio: true,
    hookEvents: container.getAttribute('events') !== 'false'
  })

  if (!micrioComponent.micrio) {
    micrioComponent.micrio = container.micrio
  }
}

class AudioPlayer {
  constructor (micrioComponent, isHome) {
    this.micrioComponent = micrioComponent
    this.micrio = micrioComponent.micrio
    this.isHome = isHome
  }

  hook (_audio, _player, _trackerline, _tracker, _time, _play, _pause, _length) {
    _audio.oncanplay = () => {
      _length.textContent = this.formatTime(_audio.duration)

      _tracker.onmousedown = ev => {
        // Only respond to LMB
        if (ev.which !== 1) return
        const percent = ev.offsetX / _tracker.offsetWidth
        _audio.currentTime = percent * _audio.duration

        // If not playing, start
        _audio.play()
      }
    }

    _audio.ontimeupdate = () => {
      _time.textContent = this.formatTime(_audio.currentTime)
      _trackerline.style.width = (_audio.currentTime / _audio.duration) * 100 + '%'
    }

    _audio.onended = () => {
      _audio.currentTime = 0
      _trackerline.style.width = '0%'
      _time.textContent = this.formatTime(_audio.duration)
    }

    _play.onclick = () => _audio.play()
    _pause.onclick = () => _audio.pause()

    _audio.onplay = () => {
      _player.classList.remove('paused')
      _player.classList.remove('ended')

      // Pause all playing micrio audios
      this.micrioComponent.muteMicrio()
    }
    _audio.onpause = () => {
      _player.classList.add('paused')
      if (!tempMuted) this.micrioComponent.unmuteMicrio()
    }

    _audio.load()
  }

  formatTime (time) {
    if (isNaN(time)) return ':-'
    let sec = Math.round(time % 60)
    if (sec < 10) sec = `0${sec}`
    return `${Math.floor(time / 60)}:${sec}`
  }
}

class KenBurns {
  constructor (micrio, isHome) {
    this.micrio = micrio
    this.isHome = isHome
    this.started = false
  }

  async start (speed) {
    if (!this.micrio || this.started) return
    this.started = true

    const maxDeltaX = 0.6
    const currentX = this.micrio.camera.coo[0]
    const targetX = currentX + (maxDeltaX * Math.random() * 2) - maxDeltaX

    // with viewport
    const height = 0.8 // hoogte ;
    const margin = 0.1 // Afstand vanaf top/bottom;
    const fromTop = margin
    const newViewport = [targetX, fromTop, targetX, fromTop + height] // voila  [x0, y0, x1, y1]

    await this.micrio.camera.flyToView(newViewport, undefined, speed)
    this.started = false
    this.start(speed).then(() => null).catch((error) => console.log(error))
  }

  restart (speed) {
    this.started = false
    this.start(speed).then(() => null).catch((error) => console.log(error))
  }

  stop () {
    if (!this.started) return
    this.micrio.camera.stop()
    this.started = false
  }
}

class MicrioController {
  constructor (micrioComponent, micrioId, lang = 'nl', element) {
    this.micrioComponent = micrioComponent
    this.micrioId = micrioId
    this.lang = lang
    this.element = element
    this.defaultState = 'intro'
    this.currentState = null
  }

  enable () {
    window.addEventListener('hashchange', () => this.handleRequest())
    this.handleRequest().catch(console.log)
  }

  buildUrl (subUrl) {
    return `#/micrio/${subUrl}`
  }

  navigate (url) {
    window.location.hash = this.buildUrl(url)
  }

  getHelpers () {
    return {
      buildUrl: this.buildUrl.bind(this),
      hookAudio: this.micrioComponent.hookAudio.bind(this.micrioComponent),
      isMuted: this.micrioComponent.isMuted.bind(this.micrioComponent),
      navigate: this.navigate.bind(this),
      setActiveMarker: this.micrioComponent.setActiveMarker.bind(this.micrioComponent),
      setPopoverThing: this.micrioComponent.setPopoverThing.bind(this.micrioComponent),
      setVolume: this.micrioComponent.setVolume.bind(this.micrioComponent),
      zoomOut: this.micrioComponent.zoomOut.bind(this.micrioComponent),
      startKenBurns: this.micrioComponent.kenBurns.restart.bind(this.micrioComponent.kenBurns),
      stopKenBurns: this.micrioComponent.kenBurns.stop.bind(this.micrioComponent.kenBurns)
    }
  }

  async handleRequest () {
    const parts = window.location.hash.split('/')
    if (parts.length < 2 || parts[1] !== 'micrio') {
      if (document.body.classList.contains('micrio-active')) {
        // Micrio not active -- set defaults
        document.body.classList.add('micrio-deactivating')
        document.body.classList.remove('micrio-active')
        this.micrioComponent.muteMicrio()
        this.micrioComponent.micrioElement.setAttribute('no-markers', '')
        await this._deactivateCurrentState().catch(console.log)
        document.body.classList.remove('micrio-deactivating')
        this.micrioComponent.zoomOut(() => {
          if (!document.body.classList.contains('micrio-active')) {
            this.micrioComponent.kenBurns && this.micrioComponent.kenBurns.restart(0.05)
          }
        })
      }
      return
    }
    document.body.classList.add('micrio-active')
    this.micrioComponent.unmuteMicrio()
    if (parts.length < 3) {
      return this.navigate(this.defaultState)
    }
    this.micrioComponent.micrioElement.removeAttribute('no-markers', '')
    const subUrl = parts.slice(2).join('/')
    const state = STATES.find(state => state.urls.some(url => subUrl.match(url.match)))
    if (this.currentState && state !== this.currentState) {
      this._deactivateCurrentState()
    }
    if (!state) {
      // Unknown route
      return
    }
    if (this.currentState !== state) {
      this.element.querySelector(`.micrio-state[data-name="${state.name}"]`).classList.add('micrio-state--active')
    }
    this.currentState = state
    const resolver = state.urls.find(url => subUrl.match(url.match))
    const match = subUrl.match(resolver.match)
    resolver.handle(this.micrioId, this.lang, this.element, this.getHelpers(), match.groups).catch(console.log)
  }

  async _deactivateCurrentState () {
    if (!this.currentState) {
      return
    }
    const state = this.currentState
    this.currentState = null
    const oldStateElement = this.element.querySelector(`.micrio-state[data-name="${state.name}"]`)
    oldStateElement.classList.remove('micrio-state--active')
    oldStateElement.classList.add('micrio-state--deactivating')
    await state.deactivate(this.micrioId, this.lang, this.element, this.getHelpers()).then(() => {
      return oldStateElement.classList.remove('micrio-state--deactivating')
    }).catch(console.log)
  }
}

export default class MicrioComponent extends Component {
  init () {
    this.micrioElement = this.element.querySelector('figure.micrio')
    this.micrioContainer = this.element.querySelector('.micrio-container')
    this.micrioId = this.element.getAttribute('data-micrio-id')
    this.micrio = null
    this.frozen = false // can we use and interact with micrio?
    this.hidden = false
    this.queue = [] // allows queueing of commands before micrio is loaded
    this.is_muted = false
    this.previousScrollPosition = null

    this.onLoad = async () => {
      this.processQueue()
    }

    // this.micrioElement.addEventListener('load', this.onLoad)

    this.initBase()
    this.initActivation()
    this.initAudioPlayer()
    this.initKenBurns()
    this.initPopover()
  }

  async initBase () {
    const documentLanguage = document.documentElement.lang
    const micrioLanguage = documentLanguage === 'nl' ? 'nl' : 'en'
    const ds = new DataService()
    const data = await ds.getData(this.micrioId, micrioLanguage)
    this.destroyMicrio()
    const id = this.micrioId
    if (!id) {
      console.log('No micrio id given!')
      return
    }
    const width = 115350
    const height = 15628
    const is360 = true
    const minimap = true
    const cover = true
    const tilesId = this.micrioId
    this.micrioController = new MicrioController(this, this.micrioId, micrioLanguage, this.element)
    data.markers.forEach(marker => {
      const e = document.createElement('a')
      e.setAttribute('slot', 'marker')
      e.setAttribute('title', marker.title)
      e.setAttribute('x', marker.x)
      e.setAttribute('y', marker.y)
      e.setAttribute('href', this.micrioController.buildUrl(`detail/${marker.slug}`))
      e.setAttribute('id', `marker__${marker.slug}`)
      this.micrioContainer.appendChild(e)
    })
    data.audio?.locations?.forEach(location => {
      const e = document.createElement('audio')
      e.setAttribute('src', location.src)
      e.setAttribute('x', location.x)
      e.setAttribute('y', location.y)
      e.setAttribute('radius', location.radius)
      e.setAttribute('volume', location.volume)
      e.setAttribute('loop', location.loop)
      e.setAttribute('interval', location.repeatAfter)
      this.micrioContainer.appendChild(e)
    })
    await this.createMicrio(this, this.micrioContainer, this.micrioContainer.querySelector('.micrio__canvas'), id, width, height, minimap, cover, is360, false, tilesId)
    this.micrio.addEventListener('load', async () => {
      await this.onLoad()
      this.micrioController.enable()
    })
    window.addEventListener('toggle-button__toggle', event => {
      if (event.detail.identifier === 'mute') {
        this.toggleMute(event.detail.state) // this.muteMicrio() : this.unmuteMicrio()
      }
    })
    this.muteMicrio()
  }

  initActivation () {
    // this.toggleActive()
    // window.addEventListener('hashchange', () => this.toggleActive())
  }

  initAudioPlayer () {
    this._doMicrioAction(() => {
      this.audioPlayer = new AudioPlayer(this, document.body.classList.contains('page--home'))
    })
  }

  initKenBurns () {
    this._doMicrioAction(() => {
      this.kenBurns = new KenBurns(this.micrio, document.body.classList.contains('page--home'))
      this.kenBurns.start(0.05).catch(console.log)
    })
  }

  initPopover () {
    const popover = this.element.querySelector('.popover')
    const closeButton = popover.querySelector('.micrio__popover-close')
    closeButton.addEventListener('click', () => {
      popover.style.display = 'none'
      this.popoverComponent.micrio.destroy()
      this.popoverComponent.micrio = null
    })
    this.popoverComponent = { element: popover }
  }

  async setPopoverThing (thing) {
    if (this.popoverComponent.micrio) {
      this.popoverComponent.micrio.destroy()
    }
    const popoverMicrioContainer = this.element.querySelector('.micrio__popover-micrio-container')
    popoverMicrioContainer.innerHTML = ''
    const figure = document.createElement('figure')
    figure.classList.add('micrio')
    const container = document.createElement('div')
    container.classList.add('micrio-container')
    const canvas = document.createElement('canvas')
    container.appendChild(canvas)
    figure.appendChild(container)
    popoverMicrioContainer.appendChild(figure)
    const id = thing.micrioId
    const width = null
    const height = null
    const is360 = false
    const minimap = false
    const cover = false
    const tilesId = thing.micrioId
    this.popoverComponent.element.style.display = 'block'
    await this.createMicrio(this.popoverComponent, container, canvas, id, width, height, minimap, cover, is360, false, tilesId)
  }

  setActiveMarker (marker) {
    if (this.activeMarker) {
      document.getElementById(`marker__${this.activeMarker.slug}`).classList.remove('active')
    }
    this.activeMarker = marker
    if (marker) {
      document.getElementById(`marker__${marker.slug}`).classList.add('active')
      this.micrioContainer.setAttribute('area-height', '150,500-')
      this.micrioContainer.setAttribute('area-width', '600,500')
      this.micrioContainer.setAttribute('area', marker.area.join(','))
    }
  }

  createMicrio () {
    const a = arguments
    if (window.__micrioReady) CMD.apply(this, a)
    else {
      if (!place.length) {
        window.__onMicrioReady = function () {
          while (place.length) {
            CMD.apply(window, place.shift())
          }
        }
      }
      place.push(a)
    }
  }

  destroyMicrio () {
    // call the function to ascertain that "this" is bound correctly
    if (this.createMicrio && this.micrio) {
      this.micrio.destroy()
    }
  }

  hookAudio (mediaReference, audioPlayer, trackerLine, tracker, currentTime, play, pause, endTime) {
    this._doMicrioAction(() => {
      this.audioPlayer.hook(mediaReference, audioPlayer, trackerLine, tracker, currentTime, play, pause, endTime)
    })
  }

  hide () {
    this._doMicrioAction(() => {
      this.hidden = true
      this.micrio.hide()
      setTimeout(() => {
        this.micrio.hide()
      }, 100)
    })
  }

  setVolume (r) {
    this._doMicrioAction(() => {
      this.micrioContainer.setAttribute('volume', r)
    })
  }

  muteMicrio () {
    tempMuted = true
    this._doMicrioAction(() => {
      this.micrio.modules && this.micrio.modules.mute()
      fireCustomEvent('toggle-button__update', { identifier: 'mute', state: true })
    })
  }

  unmuteMicrio () {
    if (!this.isMuted()) {
      tempMuted = false
      this._doMicrioAction(() => {
        !noAudio && this.micrio.modules && this.micrio.modules.unmute()
        fireCustomEvent('toggle-button__update', { identifier: 'mute', state: false })
      })
    }
  }

  toggleMute (muted = false) {
    localStorage.setItem('muted', muted ? 1 : 0)
    if (muted) {
      this.muteMicrio()
    } else {
      this.unmuteMicrio()
    }
    fireCustomEvent('toggle-button__update', { identifier: 'mute', state: muted })
    setTimeout(() => this._doMicrioAction(() => {
      this.micrio.modules.audio.locations.readHTML()
    }), 150)
  }

  isMuted () {
    return localStorage.getItem('muted') === '1'
  }

  show () {
    this._doMicrioAction(() => {
      this.hidden = false
      this.micrio.show()
      this.onLoad()
    })
  }

  zoomOut (callback) {
    this._doMicrioAction(async () => {
      if (this.micrio.camera) {
        this.micrio.camera.zoomOut(100000, 3000)
        setTimeout(() => {
          if (callback) {
            callback()
          }
        }, 3000)
      } else {
        return 1
      }
    })
  }

  processQueue () {
    // We can not guarantee that micrio isn't loading, so therefore we queue when it is loading
    if (!this.micrio || !this.micrio.isLoaded) {
      return
    }
    for (let i = 0; i < this.queue.length; i++) {
      if (!this.queue[i]()) {
        this.queue.splice(i--, 1)
      }
    }
  }

  _doMicrioAction (callback) {
    this.queue.push(callback)
    this.processQueue()
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('div.micrio').forEach(element => {
  element.instance = element.instance || new MicrioComponent(element)
}))
