
import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'

export default class MenuBarComponent extends Component {
  init () {
    this.buttonMenu = this.element.querySelector('.icon-button--menu')
    this.buttonMenu.addEventListener('click', () => fireCustomEvent('open-menu-overlay'))
    let lastScrollTop = 0
    const menuBar = document.getElementById('menu-bar__grid').parentNode
    window.addEventListener('scroll', function () {
      const st = window.pageYOffset || document.documentElement.scrollTop
      if (st > lastScrollTop) {
        if (st > (window.innerHeight + 80)) {
          menuBar.classList.add('hide')
        }
      } else {
        menuBar.classList.remove('hide')
      }
      lastScrollTop = st <= 0 ? 0 : st
    }, false)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.menu-bar').forEach(element => {
  element.instance = element.instance || new MenuBarComponent(element)
}))
