import DataService from '../data/DataService'

let micrioCanvasClickListener
const micrioCanvas = document.querySelector('.micrio__canvas')

function fixExtraSpanForContent (str) {
  const at = str.indexOf('</h3>')
  return `${str.substring(0, at + 5)}<p>${str.substring(at + 5)}</p>`
}

function buildTourTeaser (template, { title, description, buttonTitle, href }) {
  const fragment = template.content.cloneNode(true)
  const element = fragment.firstElementChild

  element.querySelector('[data-role="title"]').innerText = title
  element.querySelector('[data-role="description"]').innerText = description
  Array.from(element.querySelectorAll('[data-role="button"]')).forEach(button => {
    button.querySelector('[data-role="title"]').innerText = buttonTitle
    button.setAttribute('href', href)
  })

  return {
    element
  }
}

const STATE = {
  name: 'intro',
  urls: [
    {
      match: '^intro$',
      handle: async (micrioId, lang = 'nl', element, helpers, params) => {
        function micrioCanvasClickListener () {
          window.location = helpers.buildUrl('explore')
        }
        micrioCanvas.addEventListener('click', micrioCanvasClickListener)

        // init ken burns effect
        activateKenBurns()

        // activate ken burns on window resize event for responsiveness
        function activateKenBurns () {
          if (window.innerWidth > 800) {
            helpers.startKenBurns(0.075)
          } else {
            helpers.stopKenBurns()
          }
        }
        window.addEventListener('resize', activateKenBurns)

        const ds = new DataService()
        const info = await ds.getInfo(micrioId, lang)
        const data = await ds.getData(micrioId, lang)
        const { markerTours } = data
        const strings = info.settings._meta.trans[lang]
        const toursContainer = element.querySelector('[data-role="intro-tours-container"]')
        const tourTemplate = element.querySelector('template[data-role="tour-teaser"]')
        element.querySelector('.micrio-state__title').innerText = strings.welcome_screen_title
        element.querySelector('.micrio-state__content').innerHTML = fixExtraSpanForContent(strings.welcome_screen_content)
        element.querySelector('.micrio-state__content-detail').innerHTML = strings.welcome_screen_content_detail
        for (const tour of markerTours) {
          const { title, description, slug } = tour
          const tourData = {
            title,
            description,
            href: helpers.buildUrl(`tour/${slug}`),
            buttonTitle: strings.b_start_tour
          }
          toursContainer.append(buildTourTeaser(tourTemplate, tourData).element)
        }
        // element.querySelector('.micri  o-state__tour-button span').innerText = strings.b_start_tour
        // element.querySelector('.micrio-state__tour-button').href = helpers.buildUrl(`tour/${firstTour.slug}`)
        element.querySelector('.micrio-state__explore-button').href = helpers.buildUrl('explore')
        element.querySelector('.micrio-state__explore-button span').innerText = strings.b_free_explorer
        element.querySelector('.micrio-state--intro').scrollTo({ top: 0 })
      }
    }
  ],
  deactivate: async () => {
    micrioCanvas.removeEventListener('click', micrioCanvasClickListener)
    // wait until animation is done
    await new Promise(resolve => setTimeout(resolve, 400))
  }
}

export default STATE
