import DataService from '../data/DataService'

function smallImage (image) {
  return image.src.replace('.jpg', '.640.jpg').replace('.png', '.640.png')
}

function audioPlayer (src) {
  const template = document.querySelector('.micrio-state__templates .micrio-state__audio-player')
  const item = template.cloneNode(true)
  const audio = item.querySelector('audio')
  audio.setAttribute('src', src)
  const tracker = item.querySelector('.audio-player')
  const playButton = item.querySelector('.play')
  const pauseButton = item.querySelector('.pause')
  const progressTracker = item.querySelector('.progress-tracker')
  const progressCurrent = progressTracker.children[0]
  const progressTrackSeeker = item.querySelector('#seeker')
  const progressTrackLine = item.querySelector('.line')
  const progressEnd = progressTracker.children[2]
  return [item, audio, tracker, progressTrackLine, progressTrackSeeker, progressCurrent, playButton, pauseButton, progressEnd]
}

function tourLink (tour, step, buttonText, helpers) {
  const template = document.querySelector('.micrio-state__templates .micrio-state__tourlink-button')
  const item = template.cloneNode(true)
  item.querySelector('span').innerText = buttonText
  item.href = helpers.buildUrl(`tour/${tour.slug}/${step.slug}`)
  return item
}

async function defaultHandle (micrioId, lang = 'nl', element, helpers, params) {
  const ds = new DataService()
  const data = await ds.getData(micrioId, lang)
  const info = await ds.getInfo(micrioId, lang)
  const marker = data.markers.find(marker => marker.slug === params.markerSlug)
  if (!marker) {
    return
  }
  helpers.setActiveMarker(marker)
  helpers.setVolume(1)
  const tour = data.markerTours.find(tour => tour.slug === params.tourSlug)
  const otherTours = data.markerTours.filter(tour => tour.slug !== params.tourSlug && tour.steps.includes(marker.id))
  const strings = info.settings._meta.trans[lang]
  if (tour) {
    element.querySelector('.micrio-state__tour-title').innerText = tour.title
    element.querySelector('.micrio-state__tour-progress').innerText = `Stap ${tour.steps.indexOf(marker.id) + 1} van ${tour.steps.length}`
  }
  element.querySelector('.micrio-state__detail-title').innerText = marker.title
  element.querySelector('.micrio-state__detail-media-container').innerHTML = ''
  if ((marker.images && marker.images.length) || marker.audio || marker.embedUrl) {
    const figure = document.createElement('figure')

    if (marker.images && marker.images.length) {
      const image = document.createElement('img')
      image.setAttribute('src', smallImage(marker.images[0]))
      image.setAttribute('title', marker.images[0].description)
      image.addEventListener('click', () => helpers.setPopoverThing(marker.images[0]))
      figure.appendChild(image)
    }

    if (marker.audio) {
      const audioContext = audioPlayer(marker.audio.fileUrl)
      helpers.hookAudio(...audioContext.slice(1))
      figure.appendChild(audioContext[0])
    }

    const youtube = '^.*youtu(?:\\.be|be\\.com)/(?:.*v(?:/|=)|(?:.*/)?)([a-zA-Z0-9-_]+).*$'
    if (marker.embedUrl && marker.embedUrl.match(youtube)) {
      const videoSource = `https://www.youtube-nocookie.com/embed/${marker.embedUrl.match(youtube)[1]}`
      const iframe = document.createElement('iframe')
      iframe.setAttribute('width', '420')
      iframe.setAttribute('height', '236')
      iframe.setAttribute('src', videoSource)
      iframe.setAttribute('frameborder', '0')
      iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\\')
      iframe.setAttribute('allowfullscreen', '')
      figure.appendChild(iframe)
    }

    if (marker.data?._meta?.mediaDescription) {
      const caption = document.createElement('figcaption')
      caption.innerText = marker.data._meta.mediaDescription
      figure.appendChild(caption)
    }

    element.querySelector('.micrio-state__detail-media-container').appendChild(figure)
  }
  element.querySelector('.micrio-state__detail-body').innerHTML = marker.body
  element.querySelector('.micrio-state__detail-body-secondary').innerHTML = marker.bodySecondary

  const linksContainer = element.querySelector('.micrio-state__tour-links')
  if (tour || otherTours.length) {
    linksContainer.innerHTML = ''
    linksContainer.style.display = ''
    if (tour) {
      const stepIndex = tour.steps.indexOf(marker.id) + 1
      const isLast = stepIndex === tour.steps.length
      linksContainer.append(
        tourLink(
          tour,
          data.markers.find(m => m.id === tour.steps[isLast ? 0 : stepIndex]),
          isLast ? strings.tour_restart : strings.tour_next_step,
          helpers))
    }
    otherTours.map(t => tourLink(t, data.markers.find(m => m.id === t.steps[0]), 'Start tour', helpers)).forEach(item => linksContainer.append(item))
  } else {
    linksContainer.style.display = 'none'
  }
  element.querySelector('.micrio-state--detail').scrollTo({ top: 0 })
}

const STATE = {
  name: 'detail',
  urls: [
    {
      match: '^detail/(?<markerSlug>[\\w-]+)$',
      handle: async (micrioId, lang = 'nl', element, helpers, params) => {
        defaultHandle(micrioId, lang, element, helpers, params)
      }
    },
    {
      match: '^tour/(?<tourSlug>[\\w-]+)/(?<markerSlug>[\\w-]+)$',
      handle: async (micrioId, lang = 'nl', element, helpers, params) => {
        defaultHandle(micrioId, lang, element, helpers, params)
      }
    },
    {
      match: '^en/detail/(?<markerSlug>[\\w-]+)$',
      handle: async (micrioId, lang = 'nl', element, helpers, params) => {
        defaultHandle(micrioId, 'en', element, helpers, params)
      }
    },
    {
      match: '^en/tour/(?<tourSlug>[\\w-]+)/(?<markerSlug>[\\w-]+)$',
      handle: async (micrioId, lang = 'nl', element, helpers, params) => {
        defaultHandle(micrioId, 'en', element, helpers, params)
      }
    }
  ],
  deactivate: async (micrioId, lang = 'nl', element, helpers) => {
    console.log('deactivating detail')
    helpers.setActiveMarker(null)
  }
}

export default STATE
