
const STATE = {
  name: 'explore',
  urls: [
    {
      match: '^explore$',
      handle: async (micrioId, lang = 'nl', element, helpers, params) => {
        element.querySelector('.micrio-container').setAttribute('events', 'true')
        helpers.setVolume(1)
      }
    }
  ],
  deactivate: async (micrioId, lang = 'nl', element, helpers) => {
    element.querySelector('.micrio-container').setAttribute('events', 'false')
  }
}

export default STATE
